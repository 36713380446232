import React from 'react'
// import uhhu from '../img/uhhu.svg'
// import facebook from '../img/social/facebook.svg'
// import instagram from '../img/social/instagram.svg'
// import twitter from '../img/social/twitter.svg'
// import vimeo from '../img/social/vimeo.svg'
import logo from "../img/logo.png";

const Footer = class extends React.Component {
  render() {
    return (
      <footer>
        {/*<a href="https://uhhu.net/" className="" title="Designed by UHHU">*/}
        {/*  <img src={uhhu} alt="UHHU" style={{ width: '25px' }} />*/}
        {/*</a>*/}
        {/*<div className="container content social">*/}
        {/*  <a title="facebook" href="https://facebook.com">*/}
        {/*    <img*/}
        {/*      src={facebook}*/}
        {/*      alt="Facebook"*/}
        {/*      style={{ width: '2em', height: '2em' }}*/}
        {/*    />*/}
        {/*  </a>*/}
        {/*  <a title="instagram" href="https://instagram.com">*/}
        {/*    <img*/}
        {/*      src={instagram}*/}
        {/*      alt="Instagram"*/}
        {/*      style={{ width: '2em', height: '2em' }}*/}
        {/*    />*/}
        {/*  </a>*/}
        {/*</div>*/}
        <img src={logo} alt="Olena Isai" style={{ width: '20px' }} />
      </footer>
    )
  }
};

export default Footer
